import { IS_PROD, IS_SERVER } from '@/constants/env';
import { getLogPrefix } from '@/core/features/logger/logger';

const MAP_LOGS_ENABLED = false;

export const logMap =
    IS_SERVER || !MAP_LOGS_ENABLED || IS_PROD
        ? () => {}
        : (...message: unknown[]) => {
              // eslint-disable-next-line no-console
              return console.info(...getLogPrefix(), ...message);
          };

export const logMapRequest =
    !MAP_LOGS_ENABLED || IS_PROD
        ? () => {}
        : (...message: unknown[]) => {
              // eslint-disable-next-line no-console
              return console.info(...getLogPrefix(), ...message);
          };
