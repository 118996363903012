import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { UserQuery } from '@/core/features/a-dynamic-page/dynamic-page-types';
import type { AxiosError } from 'axios';

import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import {
    getComponentClientSide,
    getComponentQueryKey,
    updateComponentIriParam,
} from '@/core/features/a-component/services/component-service';
import { useClientUrl } from '@/core/features/app/app-atoms';
import { logger } from '@/core/features/logger/logger';
import { useQuery } from '@/core/features/react-query/react-query-service';
import { is404 } from '@/core/features/request/client-side-request';
import { DEFAULT_QUERY_STALE_TIME } from '@/core/features/request/request-constants';
import { getUrlPathWithSearch } from '@/core/utils/url';

export type QueryConfig<Component> = {
    enabled?: boolean;
    initialData?: Component | null;
    staleTime?: number;
};

type OptionsType<Component> = {
    addedUserQuery?: UserQuery;
    config?: QueryConfig<Component>;
    is404Accpeted?: boolean;
    queryName: string;
};

export default function useComponentQuery<Component extends PageComponent>(
    componentIri: null | string,
    { addedUserQuery, config, is404Accpeted = false, queryName }: OptionsType<Component>,
) {
    const [clientUrl] = useClientUrl();

    /** componentIri = null was interpreted as an object then
     * wrongly executed updateComponentIriUserQuery with an unexpected value */
    const isValidComponentIri = typeof componentIri === 'string';

    const URL = isValidComponentIri ? updateComponentIriParam('userQuery', componentIri, addedUserQuery) : null;
    const newIri = isValidComponentIri && URL ? getUrlPathWithSearch(URL.href) : '';
    const componentId = newIri ? getComponentId(newIri) : null;
    const queryKey = newIri ? getComponentQueryKey(newIri) : ['uninitialized', queryName];

    const query = useQuery<Component, AxiosError | Error>('useComponentQuery', queryKey, getQueryFn(componentId, URL), {
        enabled: config?.enabled ?? true,
        initialData: config?.initialData ?? undefined,
        staleTime: config?.staleTime ?? DEFAULT_QUERY_STALE_TIME,
    });

    if (query.error && (!is404Accpeted || !is404(query.error))) {
        logger.error('Error in component query', {
            additionalData: JSON.stringify({ componentIri, error: query.error }),
            url: clientUrl,
        });
    }

    return query;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getQueryFn = (componentId: null | string, URL: URL | null): (() => Promise<any>) | null => {
    if (!componentId || !URL) {
        return null;
    }
    return () => getComponentClientSide(componentId, URL.searchParams);
};
