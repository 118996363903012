import type { CookiesMapped } from '@/core/features/cookies/cookies-service';

import React from 'react';

import { IS_SERVER } from '@/constants/env';
import { useCookiesMapped } from '@/core/features/app/app-atoms';
import { logger } from '@/core/features/logger/logger';
import CookieStorage from '@/core/features/store/cookie-storage';
import { getURL } from '@/core/utils/url';

export type FeatureFlags = {
    indiApiHostOverride?: string;
    isMapDebugUIEnabled?: boolean;
    mockApiTimeout?: string;
    showMissingComponents?: boolean;
    trackingLogs?: boolean;
};
type FeatureFlagKey = keyof FeatureFlags;

const featureFlagCookieStorage = new CookieStorage<FeatureFlags>('indiFeatureFlags', { isTopLevel: true });

export default function FeatureFlagStore(cookiesMapped?: CookiesMapped) {
    const isActive = (featureFlagKey: FeatureFlagKey): boolean => {
        const value = featureFlagCookieStorage.getKey(featureFlagKey, cookiesMapped);
        if (value === null) {
            return false;
        }
        if (typeof value !== 'boolean') {
            logger.error(`FeatureFlagStore: isActive must return a boolean, instead is ${value}`);
            return false;
        }
        return value;
    };

    const set = <T extends FeatureFlagKey>(featureFlagKey: FeatureFlagKey, value: FeatureFlags[T]) => {
        if (value === false || value === undefined || value === null) {
            featureFlagCookieStorage.removeKey(featureFlagKey);
            return;
        }
        featureFlagCookieStorage.setKey(featureFlagKey, value);
    };

    const get = <T extends FeatureFlagKey>(featureFlagKey: T): FeatureFlags[T] => {
        if (IS_SERVER && !cookiesMapped) {
            logger.error('FeatureFlagStore: cookiesMapped is required on server side');
        }
        return featureFlagCookieStorage.getKey(featureFlagKey, cookiesMapped) as FeatureFlags[T];
    };

    const log = (featureFlagKey: FeatureFlagKey, ...message: unknown[]) => {
        if (!isActive(featureFlagKey)) {
            return;
        }

        // eslint-disable-next-line no-console
        console.info(...message);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const logTable = (featureFlagKey: FeatureFlagKey, tableTitle: string, table: object) => {
        if (!isActive(featureFlagKey)) {
            return;
        }

        // eslint-disable-next-line no-console
        console.info(tableTitle);
        // eslint-disable-next-line no-console
        console.table(table);
    };

    return { get, isActive, log, logTable, set };
}

export const useFeatureFlagStore = <T extends FeatureFlagKey>(
    featureFlagKey: T,
    options?: { reloadPage?: true },
): [FeatureFlags[T], (value: FeatureFlags[T]) => void] => {
    const cookiesMapped = useCookiesMapped();
    const featureFlagStore = React.useRef(FeatureFlagStore(cookiesMapped));
    const [featureFlagState, setFeatureFlagState] = React.useState<FeatureFlags[T]>(
        featureFlagStore.current.get(featureFlagKey),
    );

    const setFeatureFlag = React.useCallback(
        (state: FeatureFlags[T]) => {
            if (state === featureFlagStore.current.get(featureFlagKey)) {
                return;
            }

            featureFlagStore.current.set(featureFlagKey, state);
            setFeatureFlagState(state);

            const urlParam = parseUrlFeatureFlag(featureFlagKey);

            if (urlParam === state) {
                return;
            }

            const wasUpdated = updateUrl(state, featureFlagKey);

            if (wasUpdated || options?.reloadPage) {
                window.location.reload();
            }
        },
        [featureFlagKey, options],
    );

    // sync url param
    React.useEffect(() => {
        const urlParam = parseUrlFeatureFlag(featureFlagKey);

        if (urlParam === undefined) {
            return;
        }
        setFeatureFlag(urlParam as FeatureFlags[T]);
    }, [featureFlagKey, setFeatureFlag]);

    return [featureFlagState, setFeatureFlag];
};

const updateUrl = <T extends FeatureFlagKey>(state: FeatureFlags[T], featureFlagKey: FeatureFlagKey): boolean => {
    if (IS_SERVER) {
        return false;
    }
    const url = getURL(location.href);

    if (!!state) {
        return false;
    }

    url.searchParams.delete(featureFlagKey);

    if (url.href !== location.href) {
        history.pushState(null, '', url.href);
        return true;
    }
    return false;
};

const parseUrlFeatureFlag = (featureFlagKey: FeatureFlagKey): boolean | string | undefined => {
    const url = getURL(location.href);
    const urlParam = url.searchParams.get(featureFlagKey)?.toLowerCase();

    return urlParam === 'true' || urlParam === 'false' ? true : urlParam;
};
