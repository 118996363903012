/* This file was automatically generated */
export const COLORS = {
    alabasterApprox: '#f8f8f8',
    alabasterSolid: '#fafafa',
    alto: '#dcdcdc',
    anakiwa: '#9bd1fb',
    athensGray: '#efeff4',
    azureRadiance: '#0396FF',
    bittersweet: '#ff6c68',
    black: '#000000',
    catalinaBlue: '#063773',
    catalinaBlueWireframe: '#084085',
    cinnabar: '#EA4336',
    codGray: '#1d1d1d',
    concrete: '#f2f2f2',
    default: '#333333',
    defaultDesktop: '#333333',
    denim: '#1584d4',
    dodgerBlue: '#29D1FF',
    doveGray: '#666666',
    dustyGray: '#999999',
    endeavour: '#005ea8',
    foam: '#ecf7fd',
    gallery: '#ebebeb',
    gamboge: '#e29e0a',
    gardenShed: '#d4edda',
    goldDrop: '#F07C00',
    green: '#00ff3c',
    hawkesBlue: '#d9effb',
    japaneseLaurel: '#008300',
    kashmirBlue: '#4a6b91',
    keppel: '#3ca7ac',
    lemonTint: '#fef3cd',
    lima: '#7ab51d',
    lochmara: '#0271c2',
    mercury: '#e5e5e5',
    milanoRed: '#b81900',
    mineShaft: '#333333',
    modeBeige: '#96781f',
    monza: '#e30613',
    moorMonster: '#1c5724',
    mySin: '#ffbb1c',
    nobel: '#b4b4b4',
    persianRed: '#c82d2d',
    petalBloom: '#f8d7da',
    prussianBlue: '#002646',
    redBerry: '#721b25',
    roseOfSharon: '#c05702',
    scorpion: '#575757',
    selectiveYellow: '#f6b800',
    silver: '#cccccc',
    stTropaz: '#215391',
    sunflower: '#ffc618',
    tundora: '#444444',
    wasabi: '#8a9826',
    white: '#ffffff',
    wildSand: '#f4f4f4',
} as const;
