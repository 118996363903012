import type { RouteChangeEvent } from '@/core/features/router/router-events';

import React from 'react';

import { useClientUrl } from '@/core/features/app/app-atoms';
import { logger } from '@/core/features/logger/logger';
import {
    historyBack,
    historyPushState,
    historyReplaceState,
    historySessionStorage,
} from '@/core/features/router/history';
import { useRouterEvents } from '@/core/features/router/router-events';
import { savePageScrollPosition } from '@/core/features/scroll/page-scroll-restoration/scroll-history';
import { atom, useAtom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';

type OverlayHistoryStateData = unknown;

export type OverlayHistoryState = { closeType?: 'history-replace'; data?: OverlayHistoryStateData; isOpen: boolean };

export type OverlayHistoryStateRecord = Record<string, OverlayHistoryState>;

type HistoryStateWithOverlayHistory = {
    overlayHistoryStateRecord: OverlayHistoryStateRecord;
};

type CloseOverlayHistoryInterface = (
    options?: {
        closeType?: OverlayHistoryState['closeType'];
        overlayKey?: string;
    },
    data?: OverlayHistoryStateData,
) => void;

export const overlayHistoryStateRecordAtom = atom<OverlayHistoryStateRecord>({});

const updateOverlayHistoryStateRecordAtom = atom(
    null,
    (get, set, { overlayKey, state }: { overlayKey: string; state: Partial<OverlayHistoryState> }) => {
        const prev = get(overlayHistoryStateRecordAtom);
        const updatedState = getUpdatedOverlayHistoryState(prev, overlayKey, state);
        set(overlayHistoryStateRecordAtom, updatedState);
        return updatedState;
    },
);

export const useOverlayHistoryState = <HistoryStateData = undefined>(
    overlayKey: string,
    options?: { closeOnRouteChange?: true },
) => {
    const overlayHistoryStateRecordAtomState = useAtomValue(overlayHistoryStateRecordAtom);

    const updateOverlayHistoryStateRecord = useSetAtom(updateOverlayHistoryStateRecordAtom);
    const [clientUrl] = useClientUrl();
    const routerEvents = useRouterEvents();

    const overlayState = overlayHistoryStateRecordAtomState[overlayKey];

    const isOverlayOpen = overlayState?.isOpen ?? false;
    const clientUrlRef = React.useRef(clientUrl);

    const updateOverlayData = React.useCallback(
        (data: HistoryStateData) => {
            const overlayHistoryStateRecord = updateOverlayHistoryStateRecord({
                overlayKey,
                state: { data },
            });
            historyReplaceState({ overlayHistoryStateRecord });
        },
        [updateOverlayHistoryStateRecord, overlayKey],
    );

    const updateOverlayCloseType = React.useCallback(
        (closeType: OverlayHistoryState['closeType']) => {
            if (overlayState?.closeType === closeType) {
                return;
            }
            const overlayHistoryStateRecord = updateOverlayHistoryStateRecord({
                overlayKey,
                state: { closeType },
            });
            historyReplaceState({ overlayHistoryStateRecord });
        },
        [overlayState?.closeType, updateOverlayHistoryStateRecord, overlayKey],
    );

    React.useEffect(() => {
        // should the client url change while the overlay is open (e.g. filter change)
        // closing the overlay should not trigger a browser back
        // as this would negate any url change
        if (isOverlayOpen && clientUrlRef.current !== clientUrl) {
            updateOverlayCloseType('history-replace');
        }

        const routeChangeCompleteHandler: RouteChangeEvent = () => {
            clientUrlRef.current = clientUrl;
        };

        // does not apply to popstate
        // update clientUrlRef so the update will not trigger
        const popstateHandler = (event: PopStateEvent) => {
            if (event?.state === null) {
                logger.error(
                    `Popstate Event State is null for overlayKey ${overlayKey}, current url: ${location.href}, history length: ${history.length}`,
                );
                return;
            }
            clientUrlRef.current = event.state.url;
        };

        routerEvents.on('popstate', popstateHandler);
        routerEvents.on('routeChangeComplete', routeChangeCompleteHandler);
        return () => {
            routerEvents.off('popstate', popstateHandler);
            routerEvents.off('routeChangeComplete', popstateHandler);
        };
    }, [routerEvents, isOverlayOpen, clientUrl, overlayKey, updateOverlayCloseType]);

    const openOverlay = () => {
        savePageScrollPosition();

        const overlayHistoryStateRecord = updateOverlayHistoryStateRecord({
            overlayKey,
            state: { isOpen: true },
        });
        historyPushState({ overlayHistoryStateRecord });
    };

    const closeOverlay: CloseOverlayHistoryInterface = React.useCallback(
        (options) => {
            if (
                overlayState?.closeType === 'history-replace' ||
                options?.closeType === 'history-replace' ||
                options?.overlayKey
            ) {
                const overlayHistoryStateRecord = updateOverlayHistoryStateRecord({
                    overlayKey: options?.overlayKey ?? overlayKey,
                    state: { isOpen: false },
                });

                historyReplaceState({ overlayHistoryStateRecord });
            } else {
                historyBack();
            }

            // reset type
            updateOverlayCloseType(undefined);
        },
        [overlayKey, overlayState?.closeType, updateOverlayCloseType, updateOverlayHistoryStateRecord],
    );

    // handle circular linking. a links to b and b links to a would keep overlays open,
    // although they should close on link, e.g. onpage-search
    React.useEffect(() => {
        const routeChangeCompleteHandler: RouteChangeEvent = (context) => {
            if (options?.closeOnRouteChange && !context?.isPopstate && isOverlayOpen) {
                closeOverlay({ closeType: 'history-replace' });
            }
        };

        routerEvents.on('routeChangeComplete', routeChangeCompleteHandler);

        return () => {
            routerEvents.off('routeChangeComplete', routeChangeCompleteHandler);
        };
    }, [routerEvents, closeOverlay, isOverlayOpen, options]);

    /**
     * Effect: only used for debugging
     */
    // React.useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         logMap('map history state', {
    //             clientUrl,
    //             options,
    //             overlayHistoryStateRecordAtomState,
    //             overlayKey,
    //             overlayState,
    //         });
    //     }, 100);

    //     return () => {
    //         clearTimeout(timeout);
    //     };
    // }, [clientUrl, options, overlayHistoryStateRecordAtomState, overlayKey, overlayState]);

    return {
        closeOverlay,
        data: overlayState?.data,
        isOverlayOpen,
        openOverlay,
        overlayKey,
        updateOverlayData,
    };
};

export const useHydrateOverlayHistoryState = () => {
    const [overlayHistoryStateRecordAtomState, setOverlayHistoryStateRecord] = useAtom(overlayHistoryStateRecordAtom);

    const routerEvents = useRouterEvents();

    const hydrateOverlayState = React.useCallback(
        (state: unknown) => {
            const overlayHistoryStateRecord = isHistoryStateWithOverlayHistory(state)
                ? state.overlayHistoryStateRecord
                : {};

            historyReplaceState({ overlayHistoryStateRecord });

            setOverlayHistoryStateRecord(overlayHistoryStateRecord);
        },
        [setOverlayHistoryStateRecord],
    );

    // Handle initial render state hydration
    React.useEffect(() => {
        const historyState = shouldStoreHydrate(overlayHistoryStateRecordAtomState, history.state);
        if (historyState) {
            hydrateOverlayState(history.state);
            return;
        }

        if (history.scrollRestoration !== 'auto') {
            return;
        }
        const sessionStorageHistoryState = historySessionStorage.get();

        if (!sessionStorageHistoryState) {
            return;
        }
        historySessionStorage.remove();

        const sessionStorageHistory = shouldStoreHydrate(
            overlayHistoryStateRecordAtomState,
            sessionStorageHistoryState,
        );

        if (sessionStorageHistory) {
            hydrateOverlayState(sessionStorageHistory);
            return;
        }
    }, [hydrateOverlayState, overlayHistoryStateRecordAtomState]);

    // Handle Popstate
    React.useEffect(() => {
        const popstateHandler = (event: PopStateEvent) => {
            hydrateOverlayState(event.state);
        };

        routerEvents.on('popstate', popstateHandler);
        return () => {
            routerEvents.off('popstate', popstateHandler);
        };
    }, [routerEvents, overlayHistoryStateRecordAtomState, hydrateOverlayState]);
};

const isHistoryStateWithOverlayHistory = (state: unknown): state is HistoryStateWithOverlayHistory => {
    const _state = state as HistoryStateWithOverlayHistory;
    return (
        _state &&
        typeof _state === 'object' &&
        'overlayHistoryStateRecord' in _state &&
        typeof _state.overlayHistoryStateRecord === 'object' &&
        _state.overlayHistoryStateRecord &&
        Object.keys(_state.overlayHistoryStateRecord).length > 0
    );
};

const shouldStoreHydrate = (
    storeState: OverlayHistoryStateRecord,
    historyState: unknown,
): HistoryStateWithOverlayHistory | null => {
    const hasHistoryState =
        isHistoryStateWithOverlayHistory(historyState) &&
        JSON.stringify(storeState) !== JSON.stringify(historyState.overlayHistoryStateRecord);

    if (hasHistoryState) {
        return historyState;
    }
    return null;
};

const getUpdatedOverlayHistoryState = (
    prev: OverlayHistoryStateRecord,
    overlayKey: string,
    state: Partial<OverlayHistoryState>,
) => {
    const newState: OverlayHistoryState = {
        ...prev[overlayKey],
        ...state,
        data: {
            ...(prev[overlayKey]?.data ?? {}),
            ...(typeof state.data === 'object' ? state.data : {}),
        },
        isOpen: state.isOpen ?? prev[overlayKey]?.isOpen ?? false,
    };

    return {
        ...prev,
        [overlayKey]: newState,
    };
};
