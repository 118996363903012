import React from 'react';

import { atom, useAtom } from '@/core/features/store/atom-store';

type AppEventName = 'destination_change' | 'filter_change' | 'reset_destination' | 'reset_map';

const AppEventListenersAtom = atom<Record<AppEventName, Set<Function>>>({
    destination_change: new Set([]),
    filter_change: new Set([]),
    reset_destination: new Set([]),
    reset_map: new Set([]),
});

export default function useAppEvents() {
    const [listeners, setListeners] = useAtom(AppEventListenersAtom);

    const events = React.useRef({
        emit: <TEvent>(eventName: AppEventName, event?: TEvent) => {
            listeners[eventName].forEach((listener) => {
                listener(event);
            });
        },
        off: (eventName: AppEventName, listener: Function) => {
            listeners[eventName].delete(listener);
        },
        on: (eventName: AppEventName, listener: Function) => {
            listeners[eventName].add(listener);
            setListeners(listeners);
        },
    });

    return events.current;
}
